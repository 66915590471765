<template>
  <div class="promote-history">
    <UserHeader :title="'推广记录'" />
    <ul class="promote-box">
      <li class="promote-item">
        <div class="promote-title family">您的推广链接</div>
        <div class="promote-content">
          <span> {{ invite_url }}</span>
          <span class="copy" @click="authCopy()">复制</span>
        </div>
      </li>
      <li class="promote-item">
        <div class="promote-side family">您的推广码</div>
        <div class="promote-content">
          <span>{{ user.invite_code }}</span>
          <span class="copy" @click="amendCopy()">复制</span>
        </div>
      </li>
      <li class="promote-item">
        <div class="promote-side family">您的上级推广码</div>
        <div class="promote-content">
          <span>{{ user_info.inviter != null ? '' + user_info.inviter.invite_code : '暂无' }}</span>
          <span class="copy" @click="amendCopys()">复制</span>
        </div>
      </li>
    </ul>
    <div class="grade family">邀请等级</div>
    <div class="promote-profile">
      <div class="family">级别</div>
      <div class="family">下级需要</div>
      <div class="family">百分比</div>
      <div class="family">收益</div>
    </div>
    <div class="dark">
      <div>LV {{ user.promotion_level }}</div>
      <div>{{ user.invite_total_recharge }} </div>
      <div>{{ user.promotion ? user.promotion.rebate : '0.00' }}%</div>
      <div>
        {{ (user.invite_total_recharge * user.people_invited_num) * (user.promotion_level) / 100 || '0' }}
      </div>
    </div>
    <!-- <div class="promote-container">
      <button>首页</button>
      <button>上一页</button>
      <button>下一页</button>
      <button>末页</button>
    </div> -->
  </div>
</template>

<script>
import UserHeader from "@/components/userCenter/userHeader.vue";
import { mapState } from 'vuex'
import $api from '@/api/user/index'
export default {
  name: "promote",
  components: {
    UserHeader,

  },
  data() {
    return {
      invite_url: '//52skins.com/?ref_code=',
      resData: {},
      list: [],
      user: {},
    }
  },
  computed: {
    invite_url_mode() {
      return this.invite_url + this.user.invite_code
    },
    ...mapState(['user_info', 'web_info'])
  },
  created() {
    setTimeout(() => {
      this.resData = this.user_info
    }, 500)
  },
  mounted() {
    this.init()
    setTimeout(() => {
      let host = window.location.host;
      this.invite_url = 'https://' + host + '/?ref_code='
    }, 100)
  },
  methods: {
    init() {
      this.getPromote()
    },
    authCopy() {
      this.$common.CopyText({ content: this.invite_url + this.resData.invite_code })
    },
    async getPromote() {
      try {
        let { data, code } = await $api.getPromote()
        if (code == 500) return
        this.list = data.list
        this.user = data.user
      } catch (e) {
        console.log(e)
      }
    },
    async amendCopy() {
      this.$common.CopyText({ content: this.user.invite_code })
    },
    async amendCopys() {
      console.log(111111);
      this.$common.CopyText({ content: this.user_info.inviter.invite_code })
    }
  }
}
</script>

<style scoped lang="scss">
.promote-history {
  padding-top: 30px;

  /deep/.header-title {
    -webkit-text-stroke: 1px rgba(159, 218, 53, 1);
    color: transparent;
  }

  .promote-box {
    padding: 0 16px;

    .promote-item {
      margin-bottom: 10px;
      color: #fff;
      font-size: 13px;

      .promote-title {
        line-height: 18px;
        margin-bottom: 10px;
        margin-top: 70px;
      }

      .promote-side {
        line-height: 18px;
        margin-bottom: 10px;
        margin-top: 10px;
      }


      .promote-content {
        width: 100%;
        height: 45px;
        background: linear-gradient(60deg, #009FFF 0%, #05377B 100%);
        border-radius: 10px;
        padding-left: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .copy {
          color: RGBA(159, 218, 53, 1);
          margin-right: 10px;
        }
      }
    }
  }

  .grade {
    width: 100%;
    height: 28px;
    text-align: center;
    margin-top: 25px;
    font-size: 20px;
    color: transparent;
    -webkit-text-stroke: 0.5px rgba(159, 218, 53, 1);
  }

  .promote-profile {
    display: flex;
    justify-content: space-between;
    margin: 10px auto;
    width: 328px;
    height: 28px;

    div {
      width: 78px;
      height: 28px;
      border: 3px solid #0777ff;
      box-shadow: 0 0 8px 1px #383737 inset;
      border-radius: 20px;
      font-size: 14px;
      color: #009FFF;
      line-height: 26px;
      text-align: center;
    }
  }

  .dark {
    margin-bottom: 20px;
    width: 100%;
    height: 66px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: RGBA(4, 41, 92, 1);
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
  }

  .promote-container {
    width: 328px;
    height: 28px;
    margin: 60px auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #000000;

    button {
      width: 78px;
      height: 28px;
      background: linear-gradient(125deg, #009FFF 0%, #05377B 100%);
      box-shadow: inset 1px 1px 0px 0px rgba(255, 255, 255, 0.5), 2px 5px 8px 0px #000000;
      opacity: 1;
      border-radius: 20px;
      font-size: 14px;
      font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
      font-weight: 800;
      color: #009FFF;
      line-height: 28px;
    }
  }
}
</style>
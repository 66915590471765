<template>
  <div class="user-header-container">
    <span class="header-title family">{{ title }}</span>
    <van-icon name="arrow-left" class="arrow-left" @click="goBack" />
  </div>
</template>
<script>
export default {
  name: "userHeader",
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped lang="scss">
.user-header-container {
  width: 100%;
  text-align: center;
  position: relative;
  height: 44px;
  line-height: 44px;

  .arrow-left {
    position: absolute;
    top: 10px;
    left: 16px;
  }

  .header-title {
    color: RGBA(5, 55, 123, 1);
  }
}
</style>